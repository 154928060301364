.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 20px;
    align-items: center;
    background: #544B61;
    padding-top: 70px;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #303A5B, #875243, #282737);  
    background: linear-gradient(to right, #303A5B, #875243, #282737);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 20px;
    gap: 20px;
}

.image_container img {
    width: 400px;
    border-radius: 20px;
    box-shadow: 0px 0px 26px -3px rgb(90, 90, 90);
}

.details {
    max-width: 400px;
    color: white;
    display: flex;
    flex-direction: column;
}

.btns {
    display: flex;
    flex-direction: flex-start;
    gap: 10px;
}

.btns button {
    height: 50px;
}

.details h1 {
    /* font-family: "Times New Roman"; */
    font-weight: 700;
}

@media(max-width:1024px) {
    .section {
        padding-bottom: 10px;
    }

    .details {
        padding: 10px;
    }

    .container {
        padding: 10px;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .image_container img {
        max-width: 80%;
    }

    .image_container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }


}