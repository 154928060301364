.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #FFF3E9;
    padding-top: 50px;
}

.section > img {
    height: auto;
    max-width: 266px;
    margin-bottom: 16px;
    border-radius: 50%;
}

.title {
    font-size: 2rem;
    font-family: "Times New Roman";
    margin-bottom: 24px;
}

.big_letter {
    font-size: 2.2rem;
}

.description {
    margin: 24px;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
}

.author_block {
    align-self: flex-end;
    margin-top: 12px;
}

.author_name {
    font-size: 1.5rem;
}

.author_desig {
    font-size: 1.2rem;
}

@media screen and (max-width: 768px) {

    .section > img {
        height: auto;
        width: 50%;
        margin-bottom: 16px;
    }

    .title {
        font-size: 20px;
    }

    .big_letter {
        font-size: 24px;
    }

    .description {
        margin: 12px 24px;
        font-size: 13px;
    }

    .author_block {
        margin-top: 12px;
        align-self: center;
    }

    .author_name {
        font-size: 1.2rem;
    }

    .author_desig {
        font-size: 1rem;
    }
}