.gallery_image {
    width: 80%;
    position: relative;
    left: 10%;
    object-fit: cover;
    object-position: center;
    transition: transform 0.3s ease-in-out;
    border-radius: 16px;
    box-shadow: 0 0 8px black;
}

.thumbs {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 80%; 
    margin-top: 20px;
}

.thumbs img {
    width: 100%;
    left: 0;
}

.btn_prev, .btn_next {
    margin: 0 10px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .gallery_image {
        border-radius: 8px;
    }
}