.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 50px;
}

.title {
    font-size: 2rem;
    font-family: "Times New Roman";
    margin-bottom: 24px;
}

.big_letter {
    font-size: 2.2rem;
}

.info_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap-reverse;
    color: #825000;
}

.info_container > * {
    width: calc(50% - 24px);
    padding: 12px;
    min-width: min(100%, 300px);
}

.info_container > table td {
    padding: 0 4px;
}

.info_container > table > tr > td:first-child {
    white-space: nowrap;
}

.info_container > img {
    border-radius: 24px;
    object-fit: contain;
    border-radius: 16px;
}

@media screen and (max-width: 768px) {

    .section > img {
        height: auto;
        width: 50%;
        margin-bottom: 16px;
    }

    .title {
        font-size: 20px;
    }

    .big_letter {
        font-size: 24px;
    }

    .info_container {
        width: 100%;
    }

    .info_container > table {
        font-size: 14px;
    }
}