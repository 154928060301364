.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #FFF3E9;
    padding-top: 50px;
}

.title {
    font-size: 2rem;
    font-family: "Times New Roman";
}

.big_letter {
    font-size: 2.2rem;
}

.description {
    padding: 24px;
    max-width: 1000px;
    display: flex;
    background-color: #FEF2E2;
    border: 1px solid #C49E7D;
    border-radius: 16px;
    color: #825000;
    margin-top: 12px;
    width: calc(85% - 48px);
}

.video_container {
    margin: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
}

.video_container > div:first-child {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.video_container iframe {
    margin: 12px;
}

.video_container > div:first-child > iframe {
    width: 100%;
}

.video_container img {
    width: calc(50% - 24px);
    margin: 12px;
}

@media screen and (max-width: 768px) {

    .section > img {
        height: auto;
        width: 50%;
        margin-bottom: 16px;
    }

    .title {
        font-size: 20px;
    }

    .big_letter {
        font-size: 24px;
    }

    .description {
        font-size: 14px;
    }

    .video_container {
        margin: 12px 24px;
        flex-direction: column;
        align-items: center;
    }

    .video_container > div:first-child {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .video_container > div:first-child > img, .video_container > div:first-child > iframe {
        width: 80%;
    }

    .video_container > iframe {
        width: 80%;
    }
}