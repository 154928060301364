.footer {
    background-color: #FFF3E9;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.divider {
    height: 4px;
    border-top: 1px solid #824500;
    border-bottom: 1px solid #824500;
    width: 100%;
}

.footer_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 24px 0;
    color: #822F00;
    cursor: pointer;
}

.footer_content > img {
    height: 40px;
    width: 40px;
}
.footer .address{
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 10px;
}
.footer .address *, .address_header{
    color: #824500;
}
.footer .address>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap:10px;
    padding: 20px;
}
.footer .address>div *{
    text-align: center;
}
.footer .address>div>span{
    max-width: 250px;
}
.help_items *, .help_header{
    color: #824500;
}
.help_items>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}
.help_items{
    display: flex;
    justify-content: center;
}
.help_header{
    margin-top: 30px;
}

@media(max-width:650px){
    .footer .address>div *{
        text-align: left;
    }

}