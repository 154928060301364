.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFF3E9;
    padding-top: 50px;
    width: 100%;
}

.title {
    font-size: 2rem;
    font-family: "Times New Roman";
}

.big_letter {
    font-size: 2.2rem;
}

.video_container {
    margin: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.video_container > iframe {
    width: 45%;
}

@media screen and (max-width: 768px) {

    .section {
        padding-top: 30px;
        width: 100%;
    }

    .section > img {
        height: auto;
        width: 50%;
        margin-bottom: 16px;
    }

    .title {
        font-size: 20px;
    }

    .big_letter {
        font-size: 24px;
    }

    .video_container {
        margin: 12px 24px;
        flex-direction: column;
        align-items: center;
    }

    .video_container > iframe {
        width: 90%;
    }

    .video_container > iframe:first-child {
        margin-bottom: 12px;
    }
}