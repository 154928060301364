.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* min-height: calc(100vh - 116px); */
    background-color: #FFF3E9;
    padding-top: 50px;
}

.title {
    /* display: none; */
    font-size: 2rem;
    font-family: "Times New Roman";
    margin-bottom: 30px;
    max-width: 80%;
    text-align: center;
}

.big_letter {
    font-size: 24px;
}

.image_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}

.image_container > img {
    margin: 0 -54px;
    cursor: pointer;
}

.side_image_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 24px;
    background-color: #AE2018;
    border-radius: 50%;
    width: 350px;
    height: 350px;
    z-index: 2;
    position: relative;
    box-shadow: -4px 4px 12px 2px rgba(0, 0, 0, 0.5)
}

.side_image_container > img:first-child {
    width: 100px;
    height: 100px;
    border: 2px solid black;
    border-radius: 12px;
    margin-bottom: 8px;
}

.side_image_container > img:nth-child(2) {
    height: 100px;
    border: 2px solid black;
    border-radius: 12px;
}

.side_image_container > a {
    all: unset;
    color: black;
    cursor: pointer;
}

.text_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.text_container > img {
    width: 40px;
    height: 40px;
}

.cta_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 24px;
    width: 100%;
}

.secondary_cta {
    padding: 12px 24px;
    border-radius: 12px;
    background-color: #FEF2E2;
    border: 2px solid #C49E7D;
    min-width: 200px;
    color: #825000;
}

.secondary_cta:first-child {
    cursor: pointer;
}

.primary_cta {
    padding: 12px 24px;
    border-radius: 12px;
    background: linear-gradient(269.51deg, #008B59 -4.52%, #023C49 100%);
    color: white;
    font-size: 24px;
    font-weight: 600;
    border-radius: 12px;
    box-shadow: 2px 7px 10px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.info_row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: 40px;
    width: 100%;
}

@media screen and (max-width : 768px) {
    /* .section {
        min-height: calc(100vh - 54px);
    } */

    .title {
        display: block;
        margin-bottom: 32px;
        margin-top: 16px;
        font-size: 20px;
    }

    .image_container > img {
        height: 120px;
        margin: 0 -40px;
    }

    .side_image_container {
        width: 120px;
        height: 120px;
    }

    .side_image_container > img:first-child {
        width: 30px;
        height: 30px;
        margin-bottom: 4px;
        border: 1px solid black;
        border-radius: 4px;
    }

    .side_image_container > img:nth-child(2) {
        height: 30px;
        border: 1px solid black;
        border-radius: 4px;
    }

    .side_image_container > a {
        display: none;
    }

    .text_container {
        font-size: 13px;
    }

    .text_container > img {
        width: 32px;
        height: 32px;
    }

    .cta_row {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .primary_cta {
        margin-bottom: 16px;
        border-radius: 20px;
    }

    .secondary_cta {
        min-width: 0px;
        padding: 0 40px;
        border-radius: 20px;
        border: 1px solid #C49E7D;
        text-transform: lowercase;
        font-family: "Times New Roman";
        color: #824500;
    }

    .secondary_cta:first-child > svg {
        display: none !important;
    }

    .secondary_cta:last-child {
        display: none !important;
    }

    .info_row { 
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
    }
}