
.navbar{
    background: #fff3e9;
    position: fixed;
    z-index: 999;
    backdrop-filter: blur(8px);
}
.navbarNav a{
    padding: 10px;
    font-weight: 500;
}