body{
    background-color: #FFF3E9;
}

.primary_btn {
    background-image: linear-gradient(to right, rgba(196, 187, 34, 0.3) 0% , rgba(25, 201, 255, 0.3) 100%);
    background-color: #8A005C;
    color: white;
    border-radius: 40px;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    padding: 10px;
    width: 30%;
    cursor: pointer;
    text-shadow: 0 0 4px white;
    box-shadow: 0 0 8px rgb(66, 66, 66);
    border: 0;
}
.secondary_btn {
    background-image: linear-gradient(to right, rgba(196, 187, 34, 0.3) 0% , rgba(25, 201, 255, 0.3) 100%);
    border-color: #8A005C;
    color: #8A005C;
    border-radius: 40px;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    padding: 10px;
    width: 30%;
    cursor: pointer;
    text-shadow: 0 0 4px white;
    box-shadow: 0 0 8px rgb(173, 173, 173);
    border: 0;
}

.primary_btn:hover {
    background-image: none;
}

.swal2-container{
    z-index: 99999 !important;
}

