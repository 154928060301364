.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #FFF3E9;
    width: 100%;
    padding-top: 70px;
}

.order_body{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.order_body img{
    max-height: 90vh;
    object-fit: contain;
    max-width: 100%;
}
.title {
    font-size: 2rem;
    font-family: "Times New Roman";
}

.options{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    width: 75%;
    margin-top: 50px;
    margin-bottom: 50px;
}

.option{
    width: 160px;
    height: 160px;
    background-color: #FEF2E2;
    /* border: 1px solid #C49E7D; */
    box-shadow: 0px 0px 15px -3px rgb(192, 192, 192);
    border-radius: 150px;
    color: #825000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    gap: 5px;
    padding: 10px;
}
.option b{
    max-width: 75%;
    text-align: center;
}
.option h2{
    max-width: 75%;
    text-align: center;
    font-weight: 600;
    margin: 0px;
    font-size: 1.3rem;
}
.option img{
    max-width: 50%;
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 20px;
    }
    .options{
        flex-direction: column;
    }
    .instruction_sec{
        background-color: #B80606;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px;
        padding-top: 70px;
    }
  
}