.section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #FFF3E9;
    padding-bottom: 50px;
    padding-top: 50px;
    width: 100%;
}

.body {
    gap: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.img_sec {
    display: flex;
    justify-content: center;
}

.img_sec img {
    max-height: 80vh;
    border-radius: 15px;
    max-width: 85%;
}

.body_sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 2rem;
    font-family: "Times New Roman";
    margin-bottom: 30px;
    max-width: 80%;
    text-align: center;
}

.options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
}

.option {
    min-width: 175px;
    min-height: 175px;
    max-width: 175px;
    max-height: 175px;
    background-color: #FEF2E2;
    border-radius: 100px;
    color: #825000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0px 0px 15px -3px rgb(192, 192, 192);
}

/* .option:first-child{
    border: 3px solid #C49E7D;
    min-width: 200px;
    min-height: 200px;
    max-width: 200px;
    max-height: 200px;
} */
.option b {
    max-width: 75%;
    text-align: center;
}


@media screen and (max-width: 1024px) {
    
    .title {
        font-size: 20px;
    }
    
    .options {
        flex-direction: column;
        gap: 20px;
    }

    .body {
        flex-direction: column;
    }

    .option {
        min-width: 150px;
        min-height: 150px;
        max-width: 150px;
        max-height: 150px;
        font-size: 0.8rem;
    }

}