.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #FFF3E9;
    padding-top: 50px;
}

.intro_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    background-color: #FEF2E2;
    border: 1px solid #C49E7D;
    border-radius: 16px;
    padding: 24px;
    padding-top: 12px;
}

.title {
    font-size: 2rem;
    font-family: "Times New Roman";
    margin-bottom: 16px;
}

.subtitle {
    font-size: 1.2rem;
    margin: 8px 0;
    text-align: center;
    font-family: "Times New Roman";
    color: #825000;
}

.big_letter {
    font-size: 2.2rem;
}

.description {
    text-align: center;
    color: #825000;
}

.author_block {
    margin-top: 12px;
}

.author_name {
    font-size: 1.5rem;
}

.author_desig {
    font-size: 1.2rem;
}

.submit_button {
    background-image: linear-gradient(to right, rgba(196, 187, 34, 0.3) 0% , rgba(25, 201, 255, 0.3) 100%);
    margin-top: 30px;
    background-color: #8A005C;
    color: white;
    border-radius: 40px;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    padding: 10px 32px;
    cursor: pointer;
    text-shadow: 0 0 4px white;
    box-shadow: 0 0 8px black;
    border: 0;
}

.submit_button:hover {
    background-image: none;
}

@media screen and (max-width: 768px) {

    .section > img {
        height: auto;
        width: 50%;
        margin-bottom: 16px;
    }

    .title {
        font-size: 20px;
    }

    .big_letter {
        font-size: 24px;
    }

    .subtitle {
        font-size: 16px;
    }

    .description {
        margin: 12px 24px;
        font-size: 14px;
    }

    .author_block {
        margin-top: 12px;
    }

    .author_name {
        font-size: 1.2rem;
    }

    .author_desig {
        font-size: 1rem;
    }
}