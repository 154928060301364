.body {
    background-color: #FFF3E9;
    min-height: 100vh;
}

.header {
    background-color: #FFF3E9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    padding: 15px;
    z-index: 100;
}

.big_letter {
    font-size: 2.5rem;
}

.header_image {
    display: flex;
    align-items: center;
}

.header_image>img:first-child {
    width: 50px;
    height: 50px;
    margin-right: 4px;
}

.header_image>img:last-child {
    height: 50px;
    padding-top: 4px;
}

.header_image>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #AE2018;
    font-weight: 800;
}

.header_image .small_text {
    font-size: 12px;
    color: white;
}

.header_image .big_text {
    font-size: 20px;
    color: white;
}

@media screen and (max-width: 768px) {

    .header {
        padding: 12px;
        justify-content: center;
        display: none;
    }

    .header>div:nth-child(2) {
        display: none !important;
    }

    .header_image {
        margin-left: 20px;
    }

    .header_image>img:first-child {
        width: 30px;
        height: 30px;
        margin-right: 4px;
    }

    .header_image>img:last-child {
        height: 28px;
        padding-top: 2px;
    }
}