.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #FFF3E9;
    padding-top: 50px;
}

.title {
    font-size: 2rem;
    font-family: "Times New Roman";
}

.big_letter {
    font-size: 2.2rem;
}

.image_container {
    margin: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.image_container img {
    height: 200px;
    margin: 0 12px;
}

@media screen and (max-width: 768px) {

    .section > img {
        height: auto;
        width: 50%;
        margin-bottom: 16px;
    }

    .title {
        font-size: 20px;
    }

    .big_letter {
        font-size: 24px;
    }

    .image_container {
        margin: 12px 24px;
        margin-bottom: 0;
    }

    .image_container img {
        height: 100px;
    }

    .image_container > a:first-child {
        margin-bottom: 12px;
    }
}