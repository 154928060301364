.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 116px);
    background-color: #FFF3E9;
    padding-top: 20px;
}

.title {
    font-size: 2rem;
    font-family: "Times New Roman";
}

.image_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
    gap: 50px;

    flex-wrap: wrap;
}

.image_container > img {
    height: calc(100vh - 200px);
    border-radius: 16px;
}

@media screen and (max-width: 768px) {
    .section {
        min-height: calc(100vh - 54px);
    }

    .title {
        font-size: 20px;
    }

    .image_container > img {
        height: auto;
        width: 80%;
        margin-bottom: 16px;
    }
}