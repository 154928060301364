.cart_button{
    width: 60px;
    height: 60px;
    border: none;
    outline: none;
    background: none;
    background-color: #822F00;
    color: #FEF2E2;
    border-radius: 50px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
}
.cart_button .cart_count{
    background-color: #F4B887;
    color: #822F00;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
}
.cartItems{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cartItem{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.cartItem>*{
    flex: 25%;
    display: flex;
    justify-content: center;
}
.cartItem>span{
    flex: 50%;
    justify-content: flex-start;
}
.checkout_btn{
    position: absolute;
    bottom: 10px;
    width: 90%;
}
.counter{
    display: flex;
    align-items: center;
    border: 1px solid #c9ac9b;
    border-radius: 10px;
    overflow: hidden;
    justify-content: space-between;
}
.counter button, .counter div{
    width: 25px;
    max-width: 25px;
    font-size: .9rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.counter button{
    background: none;
    outline: none;
    border: none;
    background-color: #fff;
    height: 100%;
}

.btn{
    width: 100%;
    display: flex;
    justify-content: center;
}