.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #FEF2E2;
    padding-top: 50px;
}

.title {
    font-size: 2rem;
    font-family: "Times New Roman";
    margin-bottom: 24px;
}

.big_letter {
    font-size: 2.2rem;
}

.heading > h1, h2 {
    font-weight: normal;
}

.heading > h2 {
    font-size: 20px;
}

.form, .thank_you_block {
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    backdrop-filter: blur(8px);
    border-radius: 7px;
    color: #5b5b5b;
    width: 500px;
    background-color: #FEF2E2;
}

.form_title {
    text-align: center;
}

.thank_you_block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form input, .form textarea, .form select {
    border: 1px solid rgba(128, 128, 128, 0.4);
    border-radius: 10px;
    padding: 12px 8px;
    background-color: transparent;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    color: black;
    width: calc(100%);
    background-color: rgba(255, 255, 255, 0.05);
}
.form select{
    width: 100%;
}

.thank_you_block h1,h2,h3 {
    font-weight: normal;
    text-align: center;
}

.thank_you_block > h1 {
    font-size: 1.5em;
}

.thank_you_block h2 {
    font-size: 1.2em;
}

.floating_label_group {
	position: relative;
	margin-top: 20px;
}

.floating_label_group > .floating_label {
    font-size: 13px;
    color: #696969;
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    transition: all 0.1s ease;
}

.floating_label_group > textarea ~ .floating_label {
    top: 24px;
}
.floating_label_group > select ~ .floating_label {
    top: 24px;
}

.floating_label_group > input:focus ~ .floating_label, .floating_label_group > input:not(:focus):valid ~ .floating_label, .floating_label_group > textarea:focus ~ .floating_label, .floating_label_group > textarea:not(:focus):valid ~ .floating_label {
    top: -16px;
    bottom: 0px;
    left: 0px;
    font-size: 12px;
    opacity: 1;
    color: #404040;
    transform: none;
}
.floating_label_group > select:focus ~ .floating_label, .floating_label_group > select:not(:focus):valid ~ .floating_label, .floating_label_group > textarea:focus ~ .floating_label, .floating_label_group > textarea:not(:focus):valid ~ .floating_label {
    top: -16px;
    bottom: 0px;
    left: 0px;
    font-size: 12px;
    opacity: 1;
    color: #404040;
    transform: none;
}

.floating_label_group > input::-webkit-inner-spin-button, .floating_label_group > input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.floating_label_group > select::-webkit-inner-spin-button, .floating_label_group > select::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.submit_button {
    background-image: linear-gradient(to right, rgba(196, 187, 34, 0.3) 0% , rgba(25, 201, 255, 0.3) 100%);
    margin-top: 30px;
    background-color: #8A005C;
    color: white;
    border-radius: 40px;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    padding: 10px;
    width: 50%;
    cursor: pointer;
    text-shadow: 0 0 4px white;
    box-shadow: 0 0 8px black;
    border: 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.submit_button:hover {
    background-image: none;
}


@media screen and (max-width: 768px) {

    .title {
        font-size: 20px;
    }

    .big_letter {
        font-size: 24px;
    }
    
    .heading {
        width: 90%;
    }

    .heading > h1 {
        font-size: 20px;
    }

    .heading > h2 {
        font-size: 16px;
    }

    .thank_you_block > h1 {
        font-size: 20px;
    }

    .thank_you_block > h2 {
        font-size: 16px;
    }

    .form{
        width: 320px;
    }
}